import React from "react";
import { Row, Col } from "antd";
import Image from "../ImageQuerys/LandingImages";
import { colors } from "../../style-vars";

export default function RestaurantUSP({ data, lineA, lineB }) {
  return (
    <div className="restaurant-lp-main" style={{ padding: 0 }}>
      <h2 className="text-center">
        {lineA || "Powerful tools"}
        <span className="blue_dot">.</span>
      </h2>
      <h2 className="text-center">
        {lineB || "Customized to your needs"}
        <span className="blue_dot">.</span>
      </h2>
      {/* <p className="content text-center">
        Want to maximize sales and make life easier for you and your entire
        staff? With SpotOn, you can. Our cloud-based platform makes it fast and
        easy, and it always works.
      </p> */}
      <div className="restaurant-features">
        <Row gutter={[32, 32]}>
          {data.map((item, idx) => (
            <Col
              key={idx.toString()}
              span={8}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              xs={12}
            >
              <div className="item-image">
                <Image
                  imageName={item.img}
                  className="w-12 h-12 absolute left-2/4 top-2/4 -translate-y-2/4	-translate-x-2/4	"
                />
              </div>
              <p className="item-content"> {item.content}</p>
              <p className="content text-center">{item.desc}</p>
            </Col>
          ))}
        </Row>
      </div>
      <style jsx>{`
        .restaurant-lp-main {
          margin-top: 40px;
          position: relative;
          overflow: hidden;
        }

        h2 {
          font-size: 38px;
          font-weight: 700;
          line-height: 1;
          color: #090d12;
        }

        p {
          font-family: sofia-pro, sans-serif;
          margin: 0 15%;
        }

        .restaurant-lp-main :global(.hardware) {
          margin: 40px 0;
        }

        .restaurant-features {
          padding: 0 100px;
          margin: 60px 0px;
        }

        .item-image {
          width: 64px;
          height: 64px;
          position: relative;
          border-radius: 4px;
          background-color: #e8f0ff;
          display: table;
          margin: 0 auto;
        }

        .item-content {
          margin-top: 16px;
          font-family: sofia-pro, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: 19px;
          text-align: center;
        }

        .content {
          margin-top: 8px;
          font-size: 15px;
          padding: 14px;
        }

        @media screen and (max-width: 768px) {
          .restaurant-lp-main {
            padding: 32px 14px 0 14px;
            margin-top: 40px !important;
            margin-bottom: 20px !important;
          }

          .restaurant-features {
            padding: 0;
          }
          .hardware {
            min-height: 300px;
          }
          h2 {
            font-size: 24px;
          }
          p {
            margin: 0;
          }
          .content {
            margin-top: 8px;
            font-size: 14px;
          }
        }

        @media (min-width: 768px) and (max-width: 1260px) {
          .restaurant-lp-main h2 {
            font-size: 36px !important;
          }
        }
      `}</style>
    </div>
  );
}
