import React from "react";
import { string, arrayOf, any } from "prop-types";
import Image from "../ImageQuerys/RandomImages";

const Badges = (props) => {
  const { items } = props;
  return (
    <section className="max-w-[1220px] mx-auto mt-10 grid grid-cols-2 lg:grid-cols-6 border-box">
      {items.map((item) => (
        <a
          href={item.url}
          className="p-4 grid text-black text-center cursor-pointer w-[100%] border-box"
          target="_blank"
          rel="noreferrer"
          key={item.text}
        >
          <Image
            imageName={item.img}
            className="max-w-32 h-32 max-h-32 w-full justify-self-center object-scale-down"
            objectFit="scale-down"
          />
          <p className="text-p mt-2 pt-0 font-bold text-black text-center">
            {item.text}
          </p>
        </a>
      ))}
    </section>
  );
};

Badges.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: arrayOf(any),
};

Badges.defaultProps = {
  items: [
    {
      url: "https://www.capterra.com/reviews/197473/SpotOn-Restauran?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge",
      img: "badge.png",
      text: "User Reviews",
    },
    {
      url: "https://www.capterra.com/p/197473/SpotOn-Restaurant/",
      img: "Badge-1.png",
      text: "Bar POS",
    },
    {
      url: "https://www.capterra.com/p/197473/SpotOn-Restaurant/",
      img: "Badge-2.png",
      text: "Restaurant POS",
    },
    {
      url: "https://www.getapp.com/retail-consumer-services-software/a/spoton-restaurant/",
      img: "Badge-3.png",
      text: "Restaurant POS",
    },
    {
      url: "https://www.capterra.com/p/197473/SpotOn-Restaurant/",
      img: "Badge-4.png",
      text: "Bar POS",
    },
    {
      url: "https://www.softwareadvice.com/retail/spoton-restaurant-profile/",
      img: "Badge-5.png",
      text: "Best POS Software",
    },
  ],
};

export default Badges;
