import React from 'react';
import { Row, Col, Typography } from 'antd';
import Layout from '../../components/Layout/layout';
import SEO from '../../components/seo';
import RasiForm from '../../components/CustomForms/RasiForm';
import Image from '../../components/image';
import TestmonialReviews from '../../components/TestimonialReviews/TestmonialReviews';
import { LandingPageTestimonials } from '../../data/restaurant-data';
import PartnerLogos from '../../components/PartnerLogos/PartnerLogos';
import Badges from '../../components/ConversionPath/Badges';
import UspFeatures from '../../components/ConversionPath/UspFeatures';
import { SrTemplate } from '../../data/landing/sr-lp';

import ogImage from '../../images/global_assets/og-image.png';
import logoRasi from '../../images/Full-RASI-Logo-Dark-.png';
import logoSpotOn from '../../images/svg/SpotOnLogo.svg';

export default function RasiPage() {
  const { Title, Paragraph } = Typography;
  return (
    <Layout isLanding phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="Rasi + Spoton | Register a Lead"
        description="Rasi + SpotOn - Register a Lead"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        {/* <div className="demo__content-wrapper"> */}
        <Row
          justify="center"
          align="middle"
          style={{ fontSize: 42, marginTop: 40 }}
        >
          <img src={logoRasi} style={{ maxWidth: 200 }} />{' '}
          <span style={{ marginBottom: 10 }}>+</span>{' '}
          <img src={logoSpotOn} style={{ marginLeft: 20 }} />
        </Row>
        <Row justify="center" align="middle">
          <Paragraph
            className="normal-paragraph"
            style={{ textAlign: 'center', padding: 20 }}
          >
            We've teamed up together to cross promote products and services that
            help Restaurants get better
          </Paragraph>
        </Row>
        <Row
          justify="center"
          align="middle"
          style={{ maxWidth: '95%', margin: 'auto' }}
        >
          <RasiForm
            header="Register a Lead"
            leadType="rasi"
            formId="091bb640-0b33-4a08-97b4-f787b4ddb362"
            thankYouPath="/partners/thank-you"
          />
        </Row>
        <Badges />
      </section>
    </Layout>
  );
}
