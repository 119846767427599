import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Row, Col, Typography, Select } from "antd";
import Cookies from "js-cookie";
import axios from "axios";
import { usStates } from "../../data/common-data";

import chevron from "../../images/svg/chevron_left.svg";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const LPLeadForm = (props) => {
  const {
    leadType,
    header,
    subHeader,
    formId,
    phoneNumber,
    phoneText,
    thankYouPath,
  } = props;
  const form = useRef(null);
  const [loading, setLoading] = useState(false);
  const emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const phoneValidation = new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  );
  const locations = ["1-3", "4-9", "10+"];

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      fields: [
        {
          name: "firstname",
          value: values.firstname,
        },
        {
          name: "lastname",
          value: values.lastname,
        },
        {
          name: "email",
          value: values.email,
        },
        {
          name: "phone",
          value: values.phone,
        },
        {
          name: "company",
          value: values.company,
        },
        {
          name: "state",
          value: values.state,
        },
        {
          name: "locations",
          value: values.locations,
        },
        {
          name: "referred_first_name",
          value: values.refFirstName,
        },
        {
          name: "referred_last_name",
          value: values.refLastName,
        },
        {
          name: "referred_email",
          value: values.refEmail,
        },
        {
          name: "referred_by_pone_number",
          value: values.refPhone,
        },
        {
          name: "lead_notes",
          value: values.leadNotes,
        },
      ],
      context: {
        hutk: Cookies.get("hubspotutk") || undefined,
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
    axios({
      method: "post",
      url: `https://api.hsforms.com/submissions/v3/integration/submit/2970821/${formId}`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(formData),
    })
      .then(() => {
        if (window.sessionStorage.getItem("Name")) {
          window.sessionStorage.removeItem("Name", values.firstname);
          window.sessionStorage.setItem("Name", values.firstname);
        } else {
          window.sessionStorage.setItem("Name", values.firstname);
        }
        if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
          // eslint-disable-next-line no-undef
          dataLayer.push({ event: `${leadType}-lead` });
        }
      })
      .then(() => {
        window.location.pathname = thankYouPath;
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.errors[0].errorType.toString() ===
            "INVALID_EMAIL"
          ) {
            // eslint-disable-next-line no-alert
            return alert(
              `Looks like the email ${values.email} can not be validated.
                Please use a valid email.`
            );
          }
        } else if (error.request) {
          // eslint-disable-next-line no-console
          console.log(error.request);
        } else {
          // eslint-disable-next-line no-console
          console.log("Error", error.message);
        }
      });
  };
  return (
    <Col xs={23} md={22} lg={20}>
      <section
        className="lead-form-wrapper"
        ref={form}
        style={{ marginBottom: 30 }}
      >
        <Row className="header-form-wrapper">
          <Col xs={24}>
            <Title level={1} style={{ textAlign: "center" }}>
              {header}
              <span className="blue_dot">.</span>
            </Title>
          </Col>
          <Col xs={24}>
            <p style={{ fontSize: 16 }}>
              {subHeader}
              <a style={{ fontSize: 16, fontWeight: 800 }} href={phoneNumber}>
                {" "}
                {phoneText}
              </a>
            </p>
          </Col>
        </Row>

        <Form
          name="Rasi Form"
          className="lead-form in-lp"
          layout="vertical"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          {/* Name */}
          <input type="hidden" name="Rasi Form" value="contact" />
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Prospect First Name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                    min: 2,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. John" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Prospect Last Name"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                    min: 2,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. Doe" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* Email */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Prospect Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email",
                    pattern: emailValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. john@company.com" />
              </Form.Item>
            </Col>
            {/* Phone */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Prospect Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid phone",
                    pattern: phoneValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. (555) 555-1243 " />
              </Form.Item>
            </Col>
          </Row>
          {/* Business Name */}
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Prospect Company(Restaurant) Name"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Please enter a business name",
                    min: 3,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. John's Wing Shack " />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <p className="label-business-type">Prospect State</p>
              <Form.Item
                style={{ overflow: "auto" }}
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Please select a state",
                  },
                ]}
              >
                <Select
                  placeholder="CA"
                  style={{ width: "100%", color: "black !important" }}
                >
                  {usStates.map(({ abbreviation }) => (
                    <Option
                      key={abbreviation}
                      value={abbreviation}
                      className="drop-down__small"
                    >
                      {abbreviation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <p className="label-business-type"># of locations</p>
              <Form.Item
                style={{ overflow: "auto" }}
                name="locations"
                rules={[
                  {
                    required: true,
                    message: "Please select # of location",
                  },
                ]}
              >
                <Select
                  placeholder="1-3"
                  style={{ width: "100%", color: "black !important" }}
                >
                  {locations.map((location, idx) => (
                    <Option key={idx.toString()} value={location}>
                      {location}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Rasi Rep First Name"
                name="refFirstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name",
                    min: 2,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. Jane" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Rasi Rep Last Name"
                name="refLastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name",
                    min: 2,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. Doe" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* Email */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Rasi Rep Email"
                name="refEmail"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email",
                    pattern: emailValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. john@company.com" />
              </Form.Item>
            </Col>
            {/* Phone */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Rasi Rep Phone"
                name="refPhone"
                rules={[
                  {
                    required: true,
                    message: "Please prove some more details",
                    pattern: phoneValidation,
                  },
                ]}
              >
                <Input size="large" placeholder="e.g. (555) 555-1243 " />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Tell us about your deal"
                name="leadNotes"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email",
                    min: 10,
                  },
                ]}
              >
                <TextArea
                  rows={8}
                  placeholder="Start Typing ..."
                  style={{
                    border: "1px solid #77819c",
                    borderRadius: "4px !important",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="cta-primary"
                style={{ marginTop: 20 }}
                loading={loading}
                size="small"
              >
                Submit Lead
                <img
                  src={chevron}
                  alt="chevron icon"
                  style={{ margin: "5px 5px 2px 10px" }}
                />
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </section>
    </Col>
  );
};

LPLeadForm.propTypes = {
  formId: PropTypes.string.isRequired,
  leadType: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  phoneText: PropTypes.string,
  thankYouPath: PropTypes.string,
};
LPLeadForm.defaultProps = {
  phoneNumber: "",
  phoneText: "",
  thankYouPath: "/thank-you",
};

export default LPLeadForm;
